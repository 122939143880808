import assert from 'assert'
import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { StaticImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'
import type { LowPricedHeroImageQuery } from '../../types/graphql-types'
import type { PageProps } from 'gatsby'

export const Head = () => (
    <SEO
        title="ミスプラチナのブライダルリングが安い理由｜安い、安心、高品質のミスプラチナ。"
        description="プラチナ、ダイヤでも安いのは工場直販のジュエリーメーカーだからです。１万円台～の結婚指輪、６万円台～の婚約指輪など低価格で高品質が人気のブライダルリングを通販、ご来店でもご利用頂けます♪"
        page="low-priced"
    />
)

const LowPricecPage: React.FC<PageProps<LowPricedHeroImageQuery>> = ({
    data,
}) => {
    const muiTheme = useTheme()
    const imageData = data.file?.childImageSharp?.gatsbyImageData
    assert(imageData)
    const bgImage = convertToBgImage(imageData)

    return (
        <Layout breadcrumbs={[{ name: '低価格・高品質の理由' }]}>
            <Grid container spacing={0}>
                <Grid item xs={12} component="main">
                    <Hero Tag="section" {...bgImage}>
                        <HeroTextWrapper>
                            <H1>
                                <Txt size="xl">低価格・高品質の理由</Txt>
                            </H1>
                            <Txt size="xs">
                                ワークショップ直販のブライダルリング
                            </Txt>
                        </HeroTextWrapper>
                    </Hero>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">中間マージンのカット</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/low-priced/low-priced_02.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    Miss
                                    platinumは東京渋谷のジュエリー工房から直接問屋、卸売業者、小売店を挟まず、ご利用になるお客様に直接ご購入頂いております。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    そのため一般的なデパートやショップへの卸値の様な価格帯のまま、原価に近い高品質なブライダルリングとしてご利用頂いております。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">店舗運営コストのカット</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/low-priced/low-priced_03.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    東京渋谷の工房では実際に見学、試着をご利用頂けますが、全国的な店舗展開は実施しておりません。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    遠方のお客様には試着用サンプルリングを全国に無料お貸し出し配送を実施しております。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    ご自宅でも試着を楽しめるサンプル無料配送と通販を中心にご利用頂いておりますため、店舗展開によるあらゆる固定費のカットを実現し、原価に近い価格帯のまま全国のお客様にご利用頂ける様になりました。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">広告費のカット</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/low-priced/low-priced_04.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    店舗展開をしないことで一般的に年間数千万円といわれるブランディング広告、ブライダル情報誌への出稿をカット。ご自宅での無料試着、通販を中心とした工房直対応のWEBマーケティングに特化し、原価に近い価格帯のままの流通を実現。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    シンプルに品質と向き合う工房、ワークショップのブライダルリングをお楽しみください。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper>
                        <H2>
                            <Txt size="l">高品質へのこだわり</Txt>
                        </H2>
                        <H3 theme={muiTheme}>
                            <Txt size="s">PT950 ハードプラチナ</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/low-priced/low-priced_05.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    今ブライダルジュエリーで定番になりつつあるPT950ハードプラチナは純度と強度を両立した一生の記念品に相応しいワンランク上の材質。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    お好みでPT900もご利用頂けます。また、カジュアルなゴールドとして人気のK18ホワイトゴールドや、イエローゴールド、ピンクゴールドもご利用頂けます。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">品質保証書・鑑定書</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/low-priced/low-priced_06.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    エンゲージリングのダイヤモンドは国内の代表的な鑑別機関を通した確かな品質のみを使用。オプションでカラット、カラー、クラリティ、カットの4C全て表記された鑑定書、製品の品質保証書もご用意。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    オプションのダイヤはE～Hカラー /
                                    VeryGood～Good /
                                    SIクラス～、無色透明で肉眼レベルで内包物のないダイヤですが、お好みで最高級グレードでのお見積りもご案内しております。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">熟練職人の研磨・石留</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/low-priced/low-priced_07.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    お客様に納品させて頂くブライダルリングは20年以上経験を重ねた熟練職人のみが対応。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    石留めの特許も取得している技術、経験を活かし日々、工房直販の安心を守り続けています。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    一生に一度の記念品だからこそお客様にご納得して頂くまで、デザインのご相談、加工のご依頼、リペアーや返品交換対応も含め職人、製作に関わるスタッフが直接対応させて頂きます。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">豊富なデザイン</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/low-priced/low-priced_08.jpg"
                                    alt=""
                                    width={400}
                                    imgStyle={{ width: '100%' }}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    MissPlatinumの定番デザインは100種類以上、その他オーダーメイドやセミオーダーも承っております。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    お手頃価格の既製品から世界にひとつだけのオーダーメイド、ふたりでつくる体験型リング製作コースなど、工房直対応ならではの幅広い提案でご納得いただけるブライダルリングがきっと見つかります。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                </Grid>
            </Grid>
        </Layout>
    )
}

export const query = graphql`
    query LowPricedHeroImage {
        file(relativePath: { eq: "low-priced/low-priced_01.jpg" }) {
            childImageSharp {
                gatsbyImageData(formats: [WEBP])
            }
        }
    }
`

const Hero = styled(BackgroundImage)({
    height: '235px',
    position: 'relative',
})
const HeroTextWrapper = styled('div')({
    position: 'absolute',
    top: '178px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textShadow: '0 0 4px #333333',
    color: '#ffffff',
})
const H1 = styled('h1')({
    margin: 0,
})
const Wrapper = styled('section')({
    marginTop: '40px',
})
const H2 = styled('h2')({
    margin: '0 0 20px',
    display: 'flex',
    justifyContent: 'center',
})
const H3 = styled('h3')(({ theme }) => ({
    margin: 0,
    padding: '10px',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    lineHeight: 1,
}))
const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
}))
const ImageWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
        maxWidth: '320px',
        minWidth: '320px',
    },
}))
const Description = styled('p')({
    margin: '12px',
})

export default LowPricecPage
